import React from "react";

// Components
import DateSelector from "../../components/Date";
import UserSelect from "../../components/UserSelect";
import TagSelect from "../../components/TagSelect";
import Currency from "../../components/Currency";
import TextArea from "../../components/TextArea";
import DragAndDropField from "../../components/FileUpload";
import MediaCarousel from "../../components/MediaCarousel";
import Select from "../../components/Select";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Text from "../../components/Text";

const FineForm = (props) => {
  let {
    isLoading,
    onDrop,
    handleDelete,
    finedata,
    TagList,
    rowValue,
    handleTagChange,
    editable,
    handleStatusChange,
    handleNotesChange,
    handleUserChange,
    handleDateChange,
    handleDueDateChange,
    handleAmountChange,
    handleReviewerChange,
    statusList,
    showUserDetailsPageLink,
    showReviewerDetailsPageLink,
    reviewerId,
    userId,
    userList,
    typeId,
    params,
    imageUrl
  } = props;
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-sm-6">
            <DateSelector
              name="date"
              label="Date"
              placeholder="Date"
              isClearable={!editable ? true : false}
              minWidth="160px"
              required
              disabled={editable}
              onChange={handleDateChange}
            />
          </div>
          <div className="col-sm-6">
            <Select
              name="status"
              label="Status"
              placeholder="Select Status"
              onInputChange={handleStatusChange}
              options={statusList}
              required
              isDisabled={editable}
            />
          </div>
        </div>
        <UserSelect
          label="User"
          required
          isDisabled={editable}
          handleUserChange={handleUserChange}
          showUserDetailsPageLink={showUserDetailsPageLink}
          userId={userId}
        />
        <TagSelect
          name="type"
          label="Type"
          placeholder="Type"
          params={params}
          TagList={TagList}
          handleTagChange={handleTagChange}
          isDisabled={editable}
          required
          defaultValue={typeId}
        />
        <Currency
          name="amount"
          label="Amount"
          placeholder="Amount"
          disabled={editable}
          required
          onChange={handleAmountChange}
        />
        <DateSelector
          name="due_date"
          label="Due Date"
          placeholder="Date"
          isClearable={!editable ? true : false}
          minWidth="160px"
          disabled={editable}
          onChange={handleDueDateChange}
        />
        <UserSelect
          label="Reviewer"
          name="reviewer"
          isDisabled={editable}
          showUserDetailsPageLink={showReviewerDetailsPageLink}
          userId={reviewerId}
          userList={userList}
          handleUserChange={handleReviewerChange}
        />
        <div className="row">
          {props.objectName && (
            <div className="col-sm-6">
              <Text name="objectName" label="Object Name" disabled />
            </div>
          )}
          {props.objectId && (
            <div className="col-sm-6">
              <Text
                name="objectId"
                label="Object Id"
                disabled
              />
            </div>
          )}
        </div>
        <TextArea
          name="notes"
          label="Notes"
          disabled={editable}
          placeholder="Enter Notes..."
          onChange={handleNotesChange}
        />
        {!rowValue && !finedata && (
          <MediaCarousel
              showCarasoul
              Attachments
              onDropImage={onDrop}
              imageUrl={imageUrl}
              handleImageRemove={handleDelete}
              isLoading={isLoading}
            />
        )}
        {rowValue && rowValue?.id && (
          <MediaCarousel
            showCarasoul
            objectName={ObjectName.FINE}
            objectId={rowValue && rowValue.id}
            history={props.history}
            attachmentsList={true}
            Attachments={"Attachments"}
          />
        )}
      </div>
    </div>
  );
};

export default FineForm;
